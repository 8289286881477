<template>
    <div class="figuras-en-serie">
        <nav-bar sizetitle="sm" nivel="1"></nav-bar>
        <header-type-game title="Razonamiento"></header-type-game>
        <tutorial-game 
        v-if="tutorialStatus" 
        audio="tuto" 
        video="https://res.cloudinary.com/juegos-cramer/video/upload/v1617836232/juegos-cramer/tutoriales/nivel1/percepcion/series_de_figuras_bden9w.mp4"
        text="¿Qué figura falta en la serie?"></tutorial-game>
        <div v-if="!tutorialStatus&&gameRuning" >
            <clock-down minuto="1" segundo="0" @setgameruning="setGameRuning()"></clock-down>
            <div class="fes__game-box-container">
                <div class="fes__game-box">
                    <div class="fes__serie-container">
                        <div 
                        v-for="item in serie"
                        :key="item.id"
                        :class="['fes__serie-item-container',item.visible? 'fes__fig'+item.fig:'fes__interrogation']"
                        
                        >

                        </div>
                        
                    </div>
                    <div class="fes__alternatives-container">
                        <div 
                        v-for="(item,index) in alternatives"
                        :key="item.id"
                        :class="['fes__alternative-item','fes__fig'+item.fig]"
                        @click="getKeyAns(item.clave,index)"
                        >
                        <div 
                        v-if="visibleAnswer&&(index==index_answer_selected)" 
                        :class="['fes__check-answer-container',checkAnswer]"
                        ></div>
                        </div>
                        <div v-if="capaProtectora" class="fes__capa-protectora"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!gameRuning">
            <results-game nombrejuego="figuras en serie" nivel="1" inteligencia="razonamiento"></results-game>
        </div>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import HeaderTypeGame from '@/components/HeaderTypeGame'
import TutorialGame from '@/components/TutorialGame'
import ClockDown from '@/components/ClockDown'
import ResultsGame from '@/components/ResultsGame'
import {mapState,mapMutations} from 'vuex'

export default {
    components:{
        NavBar,
        HeaderTypeGame,
        TutorialGame,
        ClockDown,
        ResultsGame,
    },
    data:()=>({
        puntos:0,
        serie:[],
        gameRuning:true,
        alternatives:[],
        visibleAnswer:false,
        checkAnswer:'',
        index_answer_selected:0,
        capaProtectora:false
    }),
    computed:{
        ...mapState(['tutorialStatus'])
    },
    methods:{
         ...mapMutations(['changeMenuBarBottom','changeScoreGame','changeScoreDescription','changeTutorialStatus']),
       getKeyAns(key,index){
           
           this.visibleAnswer = true
           this.index_answer_selected = index
           this.capaProtectora = true

           if(key){
               this.puntos +=60;
               this.checkAnswer = 'fes__correct-answer'
               let a = new Audio(require('../../../assets/juegos/audios/acierto.mp3'))
                a.play()
           }
           else{
               this.puntos-=10;
               this.checkAnswer = 'fes__wrong-answer'
               let a = new Audio(require('../../../assets/juegos/audios/fallo.mp3'))
                a.play()
           }

           window.setTimeout(()=>{
               this.gameGenerator()
           },1000)
       },
       setGameRuning(){
                this.gameRuning = false
                if(this.puntos<0)
                {
                    this.changeScoreGame(0)
                    this.changeScoreDescription('insuficiente')
                }
                else{
                    this.changeScoreGame(this.puntos)
                    if(this.puntos<=200){
                    this.changeScoreDescription('insuficiente')
                    }
                    else if(this.puntos<=400){
                        this.changeScoreDescription('escasa')
                    }
                    else if(this.puntos<=600){
                        this.changeScoreDescription('suficiente')
                    }
                    else if(this.puntos<=800){
                        this.changeScoreDescription('buena')
                    }
                    else{
                        this.changeScoreDescription('optima')
                    }
                }
            },
        getRandom(min, max) {
            return Math.floor(Math.random() * (max - min+1) ) + min;
        },
        getArrayRandom(min,max,cantidad){
            //let total = max-min;
            let res = [];
            while(!(res.length==cantidad))
            {
                let aux = this.getRandom(min,max);
                res.push(aux)
            }
            return res;
        },
        reordenarArray(arr){
                let a = arr.sort(function() { return Math.random() - 0.5 })
            // console.log(a)
                return a;
            },
        gameGenerator(){
            this.visibleAnswer = false
            this.index_answer_selected = 0
            this.capaProtectora = false
            this.serie = []
            this.alternatives = []
            let arr = this.getArrayRandom(1,4,4)

            arr.forEach(e=>{
                this.serie.push({
                    fig:e,
                    visible:true
                })
            })
            let copiaArr = [...arr]
            let aux = this.getRandom(0,3)
            copiaArr.forEach((e,i)=>{
                if(i==aux){
                    this.serie.push({
                        fig:e,
                        visible:false
                    })
                }
                else{
                    this.serie.push({
                        fig:e,
                        visible:true
                    })
                }
            })

            let respuesta = this.serie.find(e=>!e.visible)

            for(let i=1;i<=4;i++)
            {
                if(i==respuesta.fig)
                {
                    this.alternatives.push({
                        fig:i,
                        clave:true
                    })
                }
                else{
                    this.alternatives.push({
                        fig:i,
                        clave:false
                    })
                }
                
            }
            this.alternatives = this.reordenarArray(this.alternatives)
        }
    },
    mounted(){
        this.gameGenerator()
        this.changeMenuBarBottom(false)
        this.changeTutorialStatus(true)
    }
}
</script>
<style>
.figuras-en-serie{
    width: 100%;
    min-height: 100vh;
    background-color: #1F0275;
}
.fes__game-box-container{
    width: 100%;
    margin-top:2em;
    display: flex;
    justify-content: center;
}
.fes__game-box{
    width: 90%;
    height: 53vh;
    background-color: #EEEFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.fes__serie-container{
    height: 3.5em;
    width: 95%;
    display: grid;
    grid-template-columns: repeat(8,12.5%);
    grid-template-rows: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
}
.fes__alternatives-container{
    height: 8em;
    width: 90%;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: repeat(4,25%);
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    position: relative;
}
.fes__alternative-item{
    width: 4em;
    height: 4em;
    border:#a1a1a1 2px solid;
    border-radius: 10px;
    background-size: 90% 90%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    cursor: pointer;
}
.fes__serie-item-container{
    width: 2.3em;
    height: 2.3em;
    background: transparent;
    box-shadow: #323232 0px 0px 10px;
    border-radius: 50%;
    background-size: 90% 90%;
    background-repeat: no-repeat;
    background-position: center;
}
.fes__check-answer-container{
    width: 2em;
    height: 2em;
    top:0px;
    right: -10px;
    position: absolute;
}
.fes__capa-protectora{
    width: 100%;
    height: 100%;
    background-color: tomato;
    position: absolute;
    opacity: 0;
}
.fes__wrong-answer{
    background-image: url('../../../assets/juegos/comunes/cancelar.svg');
}
.fes__correct-answer{
    background-image: url('../../../assets/juegos/comunes/comprobado.svg');
}
.fes__fig1{
    background-image: url('../../../assets/juegos/nivel1/razonamiento/j1/pikachu.svg');
}
.fes__fig2{
    background-image: url('../../../assets/juegos/nivel1/razonamiento/j1/bullbasour.svg');
}
.fes__fig3{
    background-image: url('../../../assets/juegos/nivel1/razonamiento/j1/snorlax.svg');
}
.fes__fig4{
    background-image: url('../../../assets/juegos/nivel1/razonamiento/j1/meow.svg');
}
.fes__interrogation{
    background-image: url('../../../assets/juegos/nivel1/razonamiento/j1/wut.png');
}
@media (min-width: 900px) and (orientation: landscape) {
    .fes__game-box{
        width: 60%;
    }
    
}
</style>